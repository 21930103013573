define(['app', 'isMobileDevice', '$location'], function(app, isMobileDevice, $location) {

  var browserSupportPage = function() {
    var self = this;

    var _config = {
      browserLogoExtension: '.png',
      selectors: {
        dataCdnUrlSelector: 'data-cdn-url',
        dataBrowserName: 'data-browser-name',
        jsonDataSelector: '[data-js-element=jsonData]',
        supportedTitleSelector: '[data-js-element=supportedTitle]',
        yourBrowserContainerSelector: '[data-js-element=yourBrowser]',
        usersBrowserContainerSelector: '[data-js-element=usersBrowser]',
        usersBrowserLogoSelector: '[data-js-element=usersBrowserLogoContainer]',
        usersBrowserTitleSelector: '[data-js-element=usersBrowserTextContainer]',
        updateLinkSelector: '[data-js-element=usersBrowserUpdateLink]',
        improveYourExperienceSelector: '[data-js-element=improveYourExperience]',
        alternativesBrowserContainerSelector: 'alternativeBrowser',
        alternativesBrowserContainerSelect: '[data-js-element=alternativeBrowser]',
        alternativesTextSelector: '[data-js-element=alternativesText]',
        alternativeBrowsersSelector: '[data-js-element=alternative]'
      },
      classes: {
        yourBrowserContainerShowClass: 'browserSupportPage_yourBrowserContainer-show',
        improveYourExperienceShowClass: 'browserSupportPage_improveYourExperience-show',
        usersBrowserNameClass: 'browserSupportPage_usersBrowserNameContainer',
        usersBrowserPaddingClass: 'browserSupportPage_usersBrowserPadding',
        usersBrowserVersionClass: 'browserSupportPage_usersBrowserVersion',
        usersBrowserUpdateShowClass: 'browserSupportPage_usersBrowserUpdateLink-show',
        alternativesTextShowClass: 'browserSupportPage_alternativesText-show',
        alternativeBrowserClass: 'browserSupportPage_alternativeBrowser',
        alternativeBrowserLogoContainerClass: 'browserSupportPage_alternativeBrowserLogoContainer',
        alternativeBrowserLogoClass: 'browserSupportPage_alternativeBrowserLogo',
        alternativeBrowserTextIconClass: 'browserSupportPage_alternativeBrowserTextIconContainer',
        alternativeBrowserTextClass: 'browserSupportPage_alternativeBrowserText',
        alternativeBrowserTitleClass: 'browserSupportPage_alternativeBrowserTitle',
        alternativeBrowserVersionClass: 'browserSupportPage_alternativeBrowserVersion',
        alternativeBrowserIconContainerClass: 'browserSupportPage_alternativeBrowserIconContainer',
        alternativeBrowserIconClass: 'browserSupportPage_alternativeBrowserIcon'
      },
      elements: {
        userBrowserDisplayNameElem: 'h2',
        userBrowserVersionElem: 'p',
        alternativeContainerElem: 'tr',
        alternativeLinkContainerElem: 'a',
        alternativeLogoContainerElem: 'div',
        alternativeLogoElem: 'img',
        alternativeTextIconElem: 'div',
        alternativeTextContainerElem: 'div',
        alternativeTitleElem: 'h2',
        alternativeVersionElem: 'p',
        alternativeIconContainerElem: 'div',
        alternativeIconElem: 'i'
      },
      properties: {
        recognised: 'improveExperienceRecognised',
        unrecognised: 'improveExperienceUnrecognised'
      }
    };

    var _init = function(element) {
      self.element = element;
      self.cdnPath = app.element.getAttribute(self.config.selectors.dataCdnUrlSelector, self.element);

      self.yourBrowserContainer = self.element.querySelector(self.config.selectors.yourBrowserContainerSelector);
      self.usersBrowserContainer = self.element.querySelector(self.config.selectors.usersBrowserContainerSelector);
      self.improveExperience = self.element.querySelector(self.config.selectors.improveYourExperienceSelector);
      self.alternativesText = self.element.querySelector(self.config.selectors.alternativesTextSelector);
      self.alternativeBrowsers = self.element.querySelector(self.config.selectors.alternativeBrowsersSelector);
      self.supportedTitle = self.element.querySelector(self.config.selectors.supportedTitleSelector);
      self.updateLink = self.element.querySelector(self.config.selectors.updateLinkSelector);
      self.usersBrowserLogo = self.element.querySelector(self.config.selectors.usersBrowserLogoSelector);
      self.usersBrowerTitle = self.element.querySelector(self.config.selectors.usersBrowserTitleSelector);
      self.supportedBrowsersString = self.element.querySelector(self.config.selectors.jsonDataSelector).innerHTML;

      self.supportedBrowsers = self.transformJsonData(self.supportedBrowsersString);
      self.userAgent = isMobileDevice.getUserAgent();
      self.usersBrowserName = null;
      self.usersBrowserVersion = null;
      self.browserSupported = false;
      self.issue = false;

      self.improveExperienceRecognisedProperty = app.utils.getProperty(self.config.properties.recognised, 'browserSupportPage');
      self.improveExperienceUnrecognisedProperty = app.utils.getProperty(self.config.properties.unrecognised, 'browserSupportPage');

      self.matchUserAgentDetails();
      self.isBrowserSupported();
      self.createUsersBrowserElem();
      self.parseAlternativeBreakdownData();
      return self;
    };

    var _transformJsonData = function(data) {
      if (data.length !== 0) {
        try {
          return JSON.parse(data);
        } catch (error) {
          return {};
        }
      }
      return {};
    };

    var _matchUserAgentDetails = function() {
      var agentRegex = /(opera|chrome|safari|firefox|msie|rv)[:|\/]?\s*(\.?\d+([^.]*)*)/i;
      var safariVersionRegex = /(version|nokiabrowser)[:|\/]?\s*(\d+)/i;
      var data = self.userAgent.toLowerCase().match(agentRegex);

      if (data.length >= 3) {
        self.usersBrowserName = data[1];
        if (self.usersBrowserName === 'safari') {
          var safariVersion = self.userAgent.toLowerCase().match(safariVersionRegex);
          if (safariVersion) {
            self.usersBrowserVersion = safariVersion[2];
            return;
          }
        }
        self.usersBrowserVersion = data[2];
      }
    };

    var _isBrowserSupported = function() {
      if (self.usersBrowserName !== null && self.supportedBrowsers.hasOwnProperty(
        self.usersBrowserName)) {
        var supportedVersion = self.supportedBrowsers[self.usersBrowserName].version;
        if (self.usersBrowserVersion >= supportedVersion) {
          self.checkExcludedVersions(self.supportedBrowsers[self.usersBrowserName].exclude);
          self.browserSupported = true;
        } else {
          app.element.addClass(self.config.classes.improveYourExperienceShowClass,
            self.improveExperience);
        }
        return;
      }
      self.issue = true;
    };

    var _checkExcludedVersions = function(versions) {
      if (versions) {
        for (var i = 0, l = versions.length; i < l; i++) {
          if (versions[i] === self.usersBrowserVersion) {
            self.browserSupported = false;
          }
        }
      }
    };

    var _createUsersBrowserElem = function() {
      var supportedTranslation = self.getSupportedTranslation();
      if (supportedTranslation) {
        self.supportedTitle.innerText = supportedTranslation;
      }

      if (self.supportedBrowsers.hasOwnProperty(self.usersBrowserName)) {
        if (!self.browserSupported) {
          self.improveExperience.innerText = self.improveExperienceRecognisedProperty;
          self.requiresUpdate(self.usersBrowserName);
        } else {
          self.improveExperience.innerText = self.improveExperienceUnrecognisedProperty;
          app.element.addClass(self.config.classes.usersBrowserPaddingClass,
            self.usersBrowserContainer);
        }
      } else {
        return;
      }
      self.showUserDetails();
    };

    var _requiresUpdate = function(browserName) {
      var updateLink = self.getUpdateLink(browserName);
      if (updateLink) {
        self.updateLink.href = self.getUpdateLink(browserName);
        app.element.addClass(self.config.classes.usersBrowserUpdateShowClass, self.updateLink);
      }
    };

    var _showUserDetails = function() {
      app.element.addClass(self.config.classes.usersBrowserUpdateShowClass,
        self.yourBrowserContainer);
      var displayNameElem = document.createElement(self.config.elements.userBrowserDisplayNameElem);
      displayNameElem.setAttribute('class', self.config.classes.usersBrowserNameClass);
      displayNameElem.innerText = self.supportedBrowsers[self.usersBrowserName].displayName;
      var versionElem = document.createElement(self.config.elements.userBrowserVersionElem);
      versionElem.setAttribute('class', self.config.classes.usersBrowserVersionClass);
      var versionTranslation = self.getVersionTranslation();
      var versionEndString = ' ' + self.usersBrowserVersion;
      versionElem.innerText = versionTranslation ? versionTranslation + versionEndString : 'Version ' + versionEndString;

      self.usersBrowserLogo.src = self.cdnPath + self.usersBrowserName + self.config.browserLogoExtension;
      self.usersBrowerTitle.appendChild(displayNameElem);
      self.usersBrowerTitle.appendChild(versionElem);
    };

    var _parseAlternativeBreakdownData = function() {
      var isAlternative = false;

      for (var browser in self.supportedBrowsers) {
        if (self.supportedBrowsers.hasOwnProperty(browser) && browser !== self.usersBrowserName
          && self.getUpdateLink(browser)) {
          var displayName = self.supportedBrowsers[browser].displayName;
          isAlternative = true;
          var alternateBrowserDom = self.createAlternativeBrowserContainer(browser,
            self.parseVersionBreakdownData(
              browser),
            displayName);

          alternateBrowserDom.addEventListener('click', self.updateBrowser);
          self.alternativeBrowsers.appendChild(alternateBrowserDom);
        }
      }
      if (isAlternative) {
        app.element.addClass(self.config.classes.alternativesTextShowClass, self.alternativesText);
      }
    };

    var _parseVersionBreakdownData = function(browser) {
      var versionsText = self.supportedBrowsers[browser].version + '+ ';
      if (self.supportedBrowsers[browser].exclude) {
        var exclude = self.supportedBrowsers[browser].exclude;
        var excludeTranslation = self.getExcludingTranslation();

        if (excludeTranslation) {
          versionsText += '(' + excludeTranslation + ' ' + exclude.join(', ') + ')';
        }
      }
      return versionsText;
    };

    var _createAlternativeBrowserContainer = function(browserName, version, displayName) {
      var containerElem = document.createElement(self.config.elements.alternativeContainerElem);
      containerElem.setAttribute('class', self.config.classes.alternativeBrowserClass);
      containerElem.setAttribute('data-js-element',
        self.config.selectors.alternativesBrowserContainerSelector);
      containerElem.setAttribute(self.config.selectors.dataBrowserName, browserName);

      var logoContainer = document.createElement(self.config.elements.alternativeLogoContainerElem);
      logoContainer.setAttribute('class', self.config.classes.alternativeBrowserLogoContainerClass);

      var logoElem = document.createElement(self.config.elements.alternativeLogoElem);
      logoElem.setAttribute('class', self.config.classes.alternativeBrowserLogoClass);
      logoElem.src = self.cdnPath + browserName + self.config.browserLogoExtension;

      var textIconContainer = document.createElement(self.config.elements.alternativeTextIconElem);
      textIconContainer.setAttribute('class', self.config.classes.alternativeBrowserTextIconClass);

      var textContainer = document.createElement(self.config.elements.alternativeTextContainerElem);
      textContainer.setAttribute('class', self.config.classes.alternativeBrowserTextClass);

      var titleElem = document.createElement(self.config.elements.alternativeTitleElem);
      titleElem.setAttribute('class', self.config.classes.alternativeBrowserTitleClass);
      titleElem.innerText = displayName;

      var versionElem = document.createElement(self.config.elements.alternativeVersionElem);
      versionElem.setAttribute('class', self.config.classes.alternativeBrowserVersionClass);
      versionElem.innerText = self.getVersionTranslation() + ' ' + version;

      var iconContainer = document.createElement(self.config.elements.alternativeIconContainerElem);
      iconContainer.setAttribute('class', self.config.classes.alternativeBrowserIconContainerClass);

      var iconElem = document.createElement(self.config.elements.alternativeIconElem);
      iconElem.setAttribute('class', self.config.classes.alternativeBrowserIconClass);

      textContainer.appendChild(titleElem);
      textContainer.appendChild(versionElem);
      iconContainer.appendChild(iconElem);
      textIconContainer.appendChild(textContainer);
      textIconContainer.appendChild(iconContainer);
      logoContainer.appendChild(logoElem);
      containerElem.appendChild(logoContainer);
      containerElem.appendChild(textIconContainer);

      return containerElem;
    };

    var _updateBrowser = function() {
      var url = self.getUpdateLink(
        app.element.getAttribute(self.config.selectors.dataBrowserName, this));

      if (url) {
        $location.assign(url);
      }
    };

    var _getUpdateLink = function(browserName) {
      if (isMobileDevice.any(self.userAgent)) {
        return self.getMobileUpdateLink(browserName);
      } else if (self.supportedBrowsers[browserName].hasOwnProperty('downloadDesktop')) {
        return self.supportedBrowsers[browserName].downloadDesktop;
      }
      return false;
    };

    var _getMobileUpdateLink = function(browserName) {
      if (isMobileDevice.android(self.userAgent)
        && self.supportedBrowsers[browserName].hasOwnProperty(
          'downloadAndroid')) {
        return self.supportedBrowsers[browserName].downloadAndroid;
      } else if (isMobileDevice.ios(self.userAgent)
        && self.supportedBrowsers[browserName]
        && self.supportedBrowsers[browserName].downloadIos) {
        return self.supportedBrowsers[browserName].downloadIos;
      }
      return false;
    };

    var _getExcludingTranslation = function() {
      return app.utils.getProperty('excluding', 'browserSupportPage');
    };

    var _getVersionTranslation = function() {
      return app.utils.getProperty('version', 'browserSupportPage');
    };

    var _getSupportedTranslation = function() {
      if (!self.issue) {
        if (!self.browserSupported) {
          return app.utils.getProperty('browserUnsupported', 'browserSupportPage');
        }
        return app.utils.getProperty('browserSupported', 'browserSupportPage');
      }
      return app.utils.getProperty('browserIssue', 'browserSupportPage');
    };

    self.config = _config;
    self.init = _init;
    self.transformJsonData = _transformJsonData;
    self.isBrowserSupported = _isBrowserSupported;
    self.checkExcludedVersions = _checkExcludedVersions;
    self.createUsersBrowserElem = _createUsersBrowserElem;
    self.showUserDetails = _showUserDetails;
    self.requiresUpdate = _requiresUpdate;
    self.matchUserAgentDetails = _matchUserAgentDetails;
    self.parseAlternativeBreakdownData = _parseAlternativeBreakdownData;
    self.parseVersionBreakdownData = _parseVersionBreakdownData;
    self.createAlternativeBrowserContainer = _createAlternativeBrowserContainer;
    self.updateBrowser = _updateBrowser;
    self.getUpdateLink = _getUpdateLink;
    self.getMobileUpdateLink = _getMobileUpdateLink;
    self.getExcludingTranslation = _getExcludingTranslation;
    self.getVersionTranslation = _getVersionTranslation;
    self.getSupportedTranslation = _getSupportedTranslation;
  };

  return browserSupportPage;
});
